export const bscZaps = [
    {
        // PancakeSwap V2
        zapAddress: '0x45381b2e118e811eC1197953d5872a10EDea09fE', //def by dyos
        ammRouter: '0x10ed43c718714eb63d5aa57b78b54704e256024e',
        ammFactory: '0xca143ce32fe78f1f7019d7d551a6402fc5350c73',
        ammPairInitHash: '0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5',
      },
      {
        // ApeSwap
        zapAddress: '0xbe8B00A861b6B223b01822C770D24Db1C8C830df', //def by dyos
        ammRouter: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        ammFactory: '0x0841BD0B734E4F5853f0dD8d7Ea041c241fb0Da6',
        ammPairInitHash: '0xf4ccce374816856d11f00e4069e7cada164065686fbef53c6167a63ec2fd8c5b',
      },
      {
        // OOE
        zapAddress: '0xDd29211787921Fb3867b816025dedC447EffEDeF',
        ammRouter: '0xBeB43fbb2f7AEA8AC904975816BB1b4cA9f4D9c5',
        ammFactory: '0xd76d8C2A7CA0a1609Aea0b9b5017B3F7782891bf',
        ammPairInitHash: '0xe7da666f616ba3bdb18c6908b22d556a41659bdd652762c246b8d1fa4f7506b4',
      },
      {
        // MDEX
        zapAddress: '0x781BF7b6d2a4C7014AB4EEAb4Bd10af540b8e020',
        ammRouter: '0x7dae51bd3e3376b8c7c4900e9107f12be3af1ba8',
        ammFactory: '0x3cd1c46068daea5ebb0d3f55f6915b10648062b8',
        ammPairInitHash: '0x0d994d996174b05cfc7bed897dc1b20b4c458fc8d64fe98bc78b3c64a6b4d093',
      },
      {
        // BabySwap
        zapAddress: '0xfd5701FB4139e6E18cE4F9712ADBAB355a6b606e',
        ammRouter: '0x325E343f1dE602396E256B67eFd1F61C3A6B38Bd',
        ammFactory: '0x86407bEa2078ea5f5EB5A52B2caA963bC1F889Da',
        ammPairInitHash: '0x48c8bec5512d397a5d512fbb7d83d515e7b6d91e9838730bd1aa1b16575da7f5',
      },
      {
        // BiSwap
        zapAddress: '0x81a1E4483f8591e77Fa5CF53cd89B61fFb05a71E',
        ammRouter: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        ammFactory: '0x858E3312ed3A876947EA49d572A7C42DE08af7EE',
        ammPairInitHash: '0xfea293c909d87cd4153593f077b76bb7e94340200f4ee84211ae8e4f9bd7ffdf',
      },
  ];
  
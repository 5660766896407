export const nativeCoins = [
    {
      chainId: 1,
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
      wrappedSymbol: 'WETH',
      allowance: Infinity,
    },
    {
      chainId: 56,
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
      wrappedSymbol: 'WBNB',
      address:"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      allowance: Infinity,
    },
    {
      chainId: 128,
      name: 'Heco Token',
      symbol: 'HT',
      decimals: 18,
      wrappedSymbol: 'WHT',
      allowance: Infinity,
    },
    {
      chainId: 43114,
      name: 'Avalance Coin',
      symbol: 'AVAX',
      decimals: 18,
      wrappedSymbol: 'WAVAX',
      allowance: Infinity,
    },
    {
      chainId: 137,
      name: 'Polygon',
      symbol: 'MATIC',
      decimals: 18,
      wrappedSymbol: 'WMATIC',
      address:"0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
      allowance: Infinity,
    },
    {
      chainId: 250,
      name: 'Fantom',
      symbol: 'FTM',
      decimals: 18,
      wrappedSymbol: 'WFTM',
      address:"0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
      allowance: Infinity,
    },
    {
      chainId: 1666600000,
      name: 'Harmony',
      symbol: 'ONE',
      decimals: 18,
      wrappedSymbol: 'WONE',
      allowance: Infinity,
    },
    {
      chainId: 42161,
      name: 'Arbitrum',
      symbol: 'ETH',
      decimals: 18,
      wrappedSymbol: 'WETH',
      allowance: Infinity,
    },
  ];
  
import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 449px;
    background-color:#0D1539;
    box-shadow: 0px 4px 39px rgba(0, 0, 0, 0.26);
    @media screen and (max-width: 1500px) {
        height: auto;
       
    };
`;
export const polygonVaults = [
    {
        id: 'giddy-giddy-usdc',
        name: 'GIDDY-USDC LP',
        token: 'GIDDY-USDC LP',
        tokenDescription: 'SushiSwap',
        tokenAddress: '0xDE990994309BC08E57aca82B1A19170AD84323E8',
        tokenDecimals: 18,
        tokenDescriptionUrl: '#',
        earnedToken: 'dyosSushiSwapGIDDY-USDC-LP',
        earnedTokenAddress: '0x2A7583D20d4ebcCFc5752c25BF548684a158a1c1',
        earnContractAddress: '0x2A7583D20d4ebcCFc5752c25BF548684a158a1c1',
        pricePerFullShare: 1,
        tvl: 0,
        oracle: 'lps',
        oracleId: 'giddy-giddy-usdc',
        depositsPaused: false,
        status: 'active',
        platform: 'SushiSwap',
        assets: ['GIDDY', 'USDC'],
        risks: [
          'PLATFORM_ESTABLISHED',
          'AUDIT',
          'CONTRACTS_VERIFIED',
        ],
        stratType: 'StratMultiLP',
        withdrawalFee: '0%',
        buyTokenUrl:
          'https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x6B3595068778DD592e39A122f4f5a5cF09C90fE2',
        addLiquidityUrl:
          'https://app.sushi.com/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x67eB41A14C0fe5CD701FC9d5A3D6597A72F641a6',
        createdAt: 1651088631,
      },

      {
        id: 'quick-lcd-matic',
        name: 'LCD-MATIC LP',
        token: 'LCD-MATIC LP',
        tokenDescription: 'QuickSwap',
        tokenAddress: '0xAab5254e17380511887aabA7e96a5339A519E26a',
        tokenDecimals: 18,
        tokenDescriptionUrl: '#',
        earnedToken: 'dyosQuickSwapLCD-MATIC-LP',
        earnedTokenAddress: '0xeF6d644c68E5242dE7C686F473Eb03b42401CF92',
        earnContractAddress: '0xeF6d644c68E5242dE7C686F473Eb03b42401CF92',
        pricePerFullShare: 1,
        tvl: 0,
        oracle: 'lps',
        oracleId: 'quick-lcd-matic',
        depositsPaused: false,
        status: 'active',
        platform: 'QuickSwap',
        assets: ['LCD', 'MATIC'],
        risks: [
          'PLATFORM_ESTABLISHED',
          'AUDIT',
          'CONTRACTS_VERIFIED',
        ],
        stratType: 'StratMultiLP',
        withdrawalFee: '0%',
        buyTokenUrl:
          'https://quickswap.exchange/#/swap?outputCurrency=0xc2A45FE7d40bCAc8369371B08419DDAFd3131b4a',
        addLiquidityUrl:
          'https://quickswap.exchange/#/add/ETH/0xc2A45FE7d40bCAc8369371B08419DDAFd3131b4a',
        createdAt: 1651088631,
      },
]
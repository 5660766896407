export const avalancheZaps = [
    {
      // Pangolin
      zapAddress: '0x7f62aF30081178F502c3d4DA17825e58d240D737',
      ammRouter: '0xE54Ca86531e17Ef3616d22Ca28b0D458b6C89106',
      ammFactory: '0xefa94DE7a4656D787667C749f7E1223D71E9FD88',
      ammPairInitHash: '0x40231f6b438bce0797c9ada29b718a87ea0a5cea3fe9a771abdd76bd41a3e545',
    },
    {
      // Olive
      zapAddress: '0xFeaaa3027F6f65bd97caE906dd85AA0Cd237702b',
      ammRouter: '0x0c45FB63001b56a21e29c7dcc1727bfDA273a368',
      ammFactory: '0x4Fe4D8b01A56706Bc6CaD26E8C59D0C7169976b3',
      ammPairInitHash: '0xb7145948956af92afd2ae97eff039ada60998237282c1687ca23ce1ad5e1d282',
    },
    {
      // Joe
      zapAddress: '0x6674f3961C5908B086A5551377806f4BA8F0Ac99',
      ammRouter: '0x60aE616a2155Ee3d9A68541Ba4544862310933d4',
      ammFactory: '0x9Ad6C38BE94206cA50bb0d90783181662f0Cfa10',
      ammPairInitHash: '0x0bbca9af0511ad1a1da383135cf3a8d2ac620e549ef9f6ae3a4c33c2fed0af91',
    },
  ];